import { Heading } from "@/shared/components/ui";
import { UIUtils } from "@/shared/utils/UIUtils";

interface ContactSpotErrorProps {
    phone: string;
    hours: string;
}

export default function ContactSpotError(props: ContactSpotErrorProps) {
    const { phone, hours } = props;
    return (
        <div className="my-4 flex flex-col items-center gap-2 text-center">
            <Heading level="h2" className="text-2xl font-semibold text-content-danger" role="alert" aria-live="polite">
                Error Spotted!
            </Heading>
            <div>
                <span className="whitespace-pre">
                    Please try again or call us at{" "}
                    <a type="tel" href={`tel:${phone}`} className="cursor-pointer underline">
                        {UIUtils.formatPhone(phone, ".")}
                    </a>{" "}
                    {`\n${hours}`}
                </span>
            </div>
        </div>
    );
}
