import { useState, useEffect, useRef } from "react";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "../contexts/FormParent";

export function useEmailSuggestion(email: string) {
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [suggestedEmail, setSuggestedEmail] = useState("");
    const { setValue: setParentValue, getValues: getParentValues } = useFormParentContext();
    const { appState, updateAppState } = useAppLayerContext();
    const { debounceObject } = appState;
    const emailUpdatingRef = useRef(false);

    useEffect(() => {
        const debounceObjectEntry = debounceObject?.[email];
        const suggestionToCheck = debounceObjectEntry?.typoFixSuggestion;
        if (
            !!suggestionToCheck &&
            suggestionToCheck !== suggestedEmail &&
            debounceObjectEntry?.userResponse === undefined &&
            email !== suggestionToCheck &&
            !emailUpdatingRef.current
        ) {
            const currentDebounce = getParentValues("extra.debounce") || {};
            const mergedDebounce = { ...currentDebounce, ...debounceObject };
            setParentValue("extra.debounce", mergedDebounce);
            updateAppState({ debounceObject: mergedDebounce });
            setSuggestedEmail(suggestionToCheck);
            setShowSuggestion(true);
        }
    }, [debounceObject, email, getParentValues, setParentValue, suggestedEmail, updateAppState]);

    const handleSuggestionConfirm = (onSuggestionAccept: (suggestedEmail: string) => void) => {
        if (!!email) {
            onSuggestionAccept(suggestedEmail);
            updateDebounceObject(true);
        }
    };

    const handleSuggestionDismiss = () => {
        if (!!email) {
            updateDebounceObject(false);
        }
    };

    const updateDebounceObject = (userResponse: boolean) => {
        const currentDebounce = getParentValues("extra.debounce");
        const updatedDebounce = { ...debounceObject, [email]: { ...debounceObject?.[email], userResponse } };
        const mergedDebounce = { ...currentDebounce, ...updatedDebounce };
        setParentValue("extra.debounce", mergedDebounce);
        updateAppState({ debounceObject: mergedDebounce });
        setShowSuggestion(false);
    };

    return {
        showSuggestion,
        suggestedEmail,
        handleSuggestionConfirm,
        handleSuggestionDismiss
    };
}
