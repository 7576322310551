import React from "react";
import { Divider } from "./ui";
import { AlertDialog, AlertDialogTitle, AlertDialogCancel, AlertDialogAction, AlertDialogPortal, AlertDialogContent, AlertDialogDescription } from "./ui/Alert-Dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
export interface ConfirmProps {
    open?: boolean;
    question?: string;
    description?: string;
    okTitle?: string;
    cancelTitle?: string;
    onClose?: (confirmed: boolean) => void;
}

export function Confirm(props: ConfirmProps) {
    function handleConfirm(confirmed: boolean) {
        if (props.onClose) {
            props.onClose(confirmed);
        }
    }

    return (
        <AlertDialog open={props.open ?? false}>
            <AlertDialogPortal>
                <AlertDialogContent onEscapeKeyDown={() => handleConfirm(false)}>
                    <AlertDialogTitle className="text-lg font-bold">{props.question ?? "Are you sure?"}</AlertDialogTitle>
                    <VisuallyHidden asChild>
                        <AlertDialogDescription>Remove pet</AlertDialogDescription>
                    </VisuallyHidden>
                    {props.description && (
                        <>
                            <Divider />
                            <p>{props.description}</p>
                        </>
                    )}
                    <div className="flex justify-end gap-2">
                        <AlertDialogCancel onClick={() => handleConfirm(false)}>{props.cancelTitle ?? "Cancel"}</AlertDialogCancel>
                        <AlertDialogAction onClick={() => handleConfirm(true)}>{props.cancelTitle ?? "OK"}</AlertDialogAction>
                    </div>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    );
}
