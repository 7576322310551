import * as React from "react";
import { Combobox } from "./Combobox";
import { Label } from "./label";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";
import { Option } from "@/shared/types/Quote.interface";

const comboboxLabelVariants = cva("relative flex", {
    variants: {
        variant: {
            default: "",
            floating: ""
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

interface ComboboxWithLabelProps extends VariantProps<typeof comboboxLabelVariants> {
    id?: string;
    label: string;
    labelId?: string;
    value?: string;
    options: Option[];
    placeholder?: string;
    searchPlaceholder?: string;
    onChange?: (value: string) => void;
    error?: ErrorMessage;
    disabled?: boolean;
    wrapperClass?: string;
    triggerProps?: React.HTMLAttributes<HTMLButtonElement>;
    withContainer?: boolean;
    keyByLabel?: boolean;
    height?: string;
    isLoading?: boolean;
}

const ComboboxWithLabel = React.forwardRef<HTMLDivElement, ComboboxWithLabelProps>((props, ref) => {
    const {
        id,
        label,
        labelId,
        value,
        options,
        placeholder,
        searchPlaceholder,
        onChange,
        error,
        disabled,
        wrapperClass,
        triggerProps,
        withContainer,
        keyByLabel,
        height,
        isLoading,
        variant
    } = props;

    const comboboxId = !id ? label?.split(" ").join("-").toLowerCase() : id;
    const isDefaultVariant = variant === "default" || variant === undefined;
    const shouldShowLabel = isDefaultVariant || (variant === "floating" && value !== undefined);

    const labelClassConditions = {
        "absolute -top-6": isDefaultVariant,
        "text-xs": !isDefaultVariant
    };

    return (
        <>
            {shouldShowLabel && (
                <Label id={labelId} htmlFor={comboboxId} variant={variant} bgColor={isDefaultVariant ? "transparent" : "default"} className={cn(labelClassConditions)}>
                    {label}
                </Label>
            )}
            <Combobox
                ref={ref}
                value={value}
                options={options}
                placeholder={placeholder}
                searchPlaceholder={searchPlaceholder}
                onChange={onChange}
                error={error}
                disabled={disabled}
                withContainer={withContainer}
                keyByLabel={keyByLabel}
                height={height}
                isLoading={isLoading}
                wrapperClass={wrapperClass}
                triggerProps={{
                    id: comboboxId,
                    "aria-labelledby": labelId,
                    ...triggerProps
                }}
            />
        </>
    );
});

ComboboxWithLabel.displayName = "ComboboxWithLabel";

export { ComboboxWithLabel };
