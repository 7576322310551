import React, { createContext, useContext, ReactNode, useMemo } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";

// Add generic type to the context
const FormParentContext = createContext<UseFormReturn<any> | null>(null);

interface FormParentContextProviderProps<T extends FieldValues> {
    children: ReactNode;
    methods: UseFormReturn<T>;
}

// Make the provider component generic
export const FormParentContextProvider = <T extends FieldValues>({ children, methods }: FormParentContextProviderProps<T>) => {
    const value = useMemo(() => methods, [methods]);

    return <FormParentContext.Provider value={value}>{children}</FormParentContext.Provider>;
};

// You could also make this hook generic if needed, but often it's used in a context where the type is already set
export const useFormParentContext = <T extends FieldValues = FieldValues>() => {
    const context = useContext(FormParentContext) as UseFormReturn<T>;
    if (!context) {
        throw new Error('Please ensure to wrap your component in a "FormParentContextProvider"!');
    }
    return context;
};
