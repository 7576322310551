import { useCallback } from "react";
import { PublicConfig } from "@/shared/PublicConfig";
import Strings from "@/shared/utils/Strings.constants";

export type StateFromZip = {
    abbreviation: string;
    name: string;
};
export default function useGiftCardZipInfo(stateFromZip: StateFromZip | undefined) {
    const getZipCodeInfo = useCallback(() => {
        const usfStates = PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_USF;
        const iaicStates = PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_IAIC;
        const iaicVerbiageV2 = PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V2;

        switch (true) {
            case !!stateFromZip?.abbreviation && usfStates.includes(stateFromZip.abbreviation):
                return {
                    bgColor: "bg-background-success",
                    text: (
                        <span>
                            <strong>{Strings.PTZ_US.GIFTCARD.APPLIED}</strong>
                        </span>
                    ),
                    iconColor: "text-content-success"
                };

            case !!stateFromZip?.abbreviation && iaicStates.includes(stateFromZip.abbreviation):
                if (!!stateFromZip?.abbreviation && iaicVerbiageV2.includes(stateFromZip.abbreviation)) {
                    return {
                        bgColor: "bg-background-success",
                        text: (
                            <span>
                                {Strings.PTZ_US.GIFTCARD.SIGN_UP} <strong>{Strings.PTZ_US.GIFTCARD.REWARD}!*</strong>
                            </span>
                        ),
                        iconColor: "text-content-success"
                    };
                } else {
                    return {
                        bgColor: "bg-background-success",
                        text: (
                            <span>
                                {Strings.PTZ_US.GIFTCARD.APPLY} <strong>{Strings.PTZ_US.GIFTCARD.REWARD}!*</strong>
                            </span>
                        ),
                        iconColor: "text-content-success"
                    };
                }
            case stateFromZip?.abbreviation && !iaicStates.includes(stateFromZip?.abbreviation) && !usfStates.includes(stateFromZip?.abbreviation):
                return {
                    bgColor: "bg-background-brand-b-secondary",
                    text: <span>{Strings.PTZ_US.GIFTCARD.UNAVAILABLE}</span>,
                    iconColor: "text-content-primary"
                };

            default:
                return {
                    bgColor: "bg-background-brand-b-secondary",
                    text: (
                        <span>
                            {Strings.PTZ_US.GIFTCARD.QUALIFY} <strong>{Strings.PTZ_US.GIFTCARD.REWARD}!*</strong>
                        </span>
                    ),
                    iconColor: "text-content-primary"
                };
        }
    }, [stateFromZip?.abbreviation]);

    return getZipCodeInfo();
}
