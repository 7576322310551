import React from "react";
import { Button } from "./ui/Button";

interface EmailSuggestionWrapperProps {
    className?: string;
    children: React.ReactNode;
    showSuggestion: boolean;
    suggestedEmail: string;
    onSuggestionConfirm: () => void;
    onSuggestionDismiss: () => void;
}

export function EmailSuggestionWrapper({ children, showSuggestion, suggestedEmail, onSuggestionConfirm, onSuggestionDismiss, className }: EmailSuggestionWrapperProps) {
    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            {children}
            {showSuggestion && (
                <div className="flex items-start justify-between gap-2">
                    <Button
                        size="auto"
                        variant="ghost"
                        className="break-words text-start text-sm text-content-danger underline hover:bg-background-primary"
                        onClick={onSuggestionConfirm}
                        style={{ wordBreak: "break-word" }}
                    >
                        Did you mean {suggestedEmail}?
                    </Button>
                    <Button size="auto" variant="ghost" className="text-sm text-content-secondary underline hover:bg-background-primary" onClick={onSuggestionDismiss}>
                        Dismiss
                    </Button>
                </div>
            )}
        </div>
    );
}
