import React, { useEffect, useRef, useCallback, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { useForm, FormProvider, FieldValues, UseFormReturn, UseFormProps, DefaultValues } from "react-hook-form";
import { ZodSchema, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";

interface FormStepWrapperProps<T extends FieldValues> {
    schema: ZodSchema<T>;
    children: React.ReactNode;
    stepId: string;
    options?: UseFormProps<T>;
    register: (stepId: string, methods: UseFormReturn<T>) => void;
    unregister: (stepId: string) => void;
}

/**
 * FormStepWrapper is a component that manages a single step in a multi-step form process.
 * It handles form state, validation, and tracks dirty (modified) fields.
 *
 * Key features:
 * - Zod schema validation
 * - Tracks form field modifications
 * - Manages form registration/unregistration
 * - Handles default values updates
 * - Debounced dirty value updates
 *
 * @template T - The type of the form values, extending FieldValues
 */
export function FormStepWrapper<T extends FieldValues>({ schema, children, options, register, unregister, stepId }: FormStepWrapperProps<T>) {
    // Initialize form with schema validation
    const methods = useForm<z.infer<typeof schema>>({ resolver: zodResolver(schema), ...options });
    const { getValues, watch, reset } = methods;
    const { updateDirtyValues } = useDirtyValues<T>();

    // Store initial values in both ref and state
    // Ref is used for persistence across rerenders, state is used for reactive updates
    const initialValuesRef = useRef<DefaultValues<T> | undefined>(typeof options?.defaultValues === "function" ? undefined : options?.defaultValues);
    const [initialValues, setInitialValues] = useState<DefaultValues<T> | undefined>(undefined);

    // Sync ref value to state on mount
    useEffect(() => {
        setInitialValues(initialValuesRef.current);
    }, []);

    // Handle form registration and cleanup
    useEffect(() => {
        register(stepId, methods);
        return () => {
            unregister(stepId);
        };
    }, [register, unregister, methods, stepId]);

    /**
     * updateDirtyValuesCallback: Compares current form values against initial values
     * to determine which fields have been modified (are "dirty"). It then updates
     * the dirty values context with these changes.
     *
     * This function:
     * 1. Retrieves current form values
     * 2. Compares each field with its initial value (if available)
     * 3. Considers a field dirty if:
     *    a) It has an initial value and the current value differs
     *    b) It had no initial value but now has a value
     * 4. Collects all dirty fields in a new object
     * 5. Updates the dirty values context if any changes are detected
     *
     * This callback is used in a watch effect to track form changes in real-time,
     * ensuring the dirty values context always reflects the current state of the form.
     */
    const updateDirtyValuesCallback = useCallback(() => {
        const currentValues = getValues();

        const dirtyValues = Object.keys(currentValues).reduce((acc, key) => {
            const typedKey = key as keyof T;
            const initialValue = initialValues && typedKey in initialValues ? initialValues[typedKey as keyof typeof initialValues] : undefined;
            const currentValue = currentValues[typedKey];

            if (initialValue !== undefined && currentValue !== initialValue) {
                acc[typedKey] = currentValue;
            } else if (initialValue === undefined && currentValue !== undefined) {
                acc[typedKey] = currentValue;
            }
            return acc;
        }, {} as Partial<T>);

        if (Object.keys(dirtyValues).length > 0) {
            updateDirtyValues(dirtyValues);
        }
    }, [getValues, updateDirtyValues, initialValues]);

    // Create debounced version of the update callback to prevent excessive updates
    const debouncedUpdateDirtyValues = useMemo(() => debounce(updateDirtyValuesCallback, 300, { maxWait: 1000 }), [updateDirtyValuesCallback]);

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedUpdateDirtyValues.cancel();
        };
    }, [debouncedUpdateDirtyValues]);

    // Watch for form changes and trigger dirty values update
    useEffect(() => {
        const subscription = watch(() => {
            debouncedUpdateDirtyValues();
        });

        return () => subscription.unsubscribe();
    }, [watch, debouncedUpdateDirtyValues]);

    // Handle updates to default values
    useEffect(() => {
        const defaultValues = options?.defaultValues;
        if (defaultValues === undefined) return;

        const resetForm = async () => {
            let values: DefaultValues<T> | undefined;

            // Handle both sync and async default values
            if (typeof defaultValues === "function") {
                try {
                    values = await (defaultValues as () => Promise<DefaultValues<T>>)();
                } catch (error) {
                    console.error("Error getting default values:", error);
                    return;
                }
            } else {
                values = defaultValues;
            }

            // Update both ref and state with new values
            initialValuesRef.current = values;
            setInitialValues(values);

            // Reset form while preserving important form state
            reset(values, {
                keepValues: true,
                keepTouched: true,
                keepDirtyValues: true,
                keepDirty: true,
                keepIsSubmitted: true,
                keepSubmitCount: true,
                keepErrors: true
            });
        };

        resetForm();
    }, [options?.defaultValues, reset]);

    return <FormProvider {...methods}>{children}</FormProvider>;
}
