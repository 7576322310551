import { Option } from "@/shared/types/Quote.interface";
import times from "lodash/times";
import { Species } from "spot-types/entities/Species";
import Strings from "./Strings.constants";
export class PolicyUtils {
    static getAges(): Option[] {
        return times(21, index => ({
            value: `${index}`,
            label: index === 0 ? Strings.PTZ_US.PUPPY_KITTEN_AGE : `${index} year${index > 1 ? "s" : ""}`
        }));
    }

    static getMonths(): Option[] {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        return months.map((month, index) => ({
            value: `${index + 1}`,
            label: month
        }));
    }

    static getYears(): Option[] {
        const currentYear = new Date().getFullYear();

        return times(21, index => ({
            value: `${currentYear - index}`,
            label: `${currentYear - index}`
        }));
    }

    static recommendedPreventiveText = (petSpecies: Species, petAge: number) => {
        const species = petSpecies.toLowerCase();
        let label = "";

        if (petAge < 3) {
            label = species === Species.Dog.toLowerCase() ? `puppies` : `kittens`;
        } else if (petAge < 6) {
            label = `${species}s ages 3-5`;
        } else if (petAge < 10) {
            label = `${species}s ages 6-9`;
        } else {
            label = `${species}s ages 10+`;
        }

        return label;
    };
}
