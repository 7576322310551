import React, { FunctionComponent, ReactNode, useState } from "react";
import { Confirm, ConfirmProps } from "@/shared/components/Confirm";

export function useModal() {
    const [modals, setModals] = useState<ReactNode[]>([]);

    function createModalControl<T = any>(type: FunctionComponent<T>, props: T) {
        const modalID = Date.now();
        const newModal = React.createElement(type as any, {
            ...props,
            key: modalID
        });
        setModals([...modals, newModal]);
        return newModal;
    }

    function removeModal(modal: ReactNode) {
        setModals(modals.filter(existingModal => existingModal === modal));
    }

    function openModal<T = any>(type: FunctionComponent<T>, props: T): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const newModal = createModalControl(type, {
                ...props,
                open: true,
                onClose: (confirmed: boolean) => {
                    resolve(confirmed);
                    removeModal(newModal);
                }
            });
        });
    }

    return {
        render: modals,
        openModal,
        confirm: (props?: Omit<ConfirmProps, "onClose" | "open">) =>
            new Promise<boolean>(resolve => {
                const newModal = createModalControl(Confirm, {
                    ...props,
                    open: true,
                    onClose: confirmed => {
                        resolve(confirmed);
                        removeModal(newModal);
                    }
                });
            })
    };
}
