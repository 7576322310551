import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState, createContext, useContext } from "react";
import { FormProps } from "@/shared/types/Form";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useFormSteps } from "../hooks/useFormSteps";
import { useFormParentContext } from "../contexts/FormParent";
import { UIUtils } from "../utils/UIUtils";
import { Button } from "./ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { StepRenderer } from "./StepRenderer";
import { Container } from "./Container";
import LoadingSpinner from "./LoadingSpinner";
import ContactSpotError from "./ContactSpotError";
import { useAppLayerContext } from "../contexts/AppLayer";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";
import LoaderWithText from "./LoaderWithText";

export function Form<StepIds extends string, T extends FieldValues>(props: FormProps<StepIds, T>) {
    const { value, otherValuesMap, form, isUpdating, initialStepID, onStepChange, onChange, onSubmitStep, uwConfig } = props;
    const { phone, hours } = uwConfig;
    const { getValues, reset } = useFormParentContext();
    const { appState, updateAppState } = useAppLayerContext();
    const { hasUnknownError, stripe, stripeElements, stripeExpressPaymentType } = appState;
    const hasInitialStepIDBeenSet = useRef(false);
    const [isValidating, setIsValidating] = useState(false);
    const { register, unregister, formStepsState } = useFormSteps();
    const continueButtonRef = useRef<HTMLButtonElement>(null);

    const registerStep = useCallback(
        (stepId: string, methods: UseFormReturn<any>) => {
            register(stepId, methods);
        },
        [register]
    );

    const unregisterStep = useCallback(
        (stepId: string) => {
            unregister(stepId);
        },
        [unregister]
    );

    const [lastStepIndex, setLastStepIndex] = useState(() => {
        if (initialStepID) {
            const startIndex = form.steps.findIndex(step => step.id === initialStepID);
            if (startIndex !== -1) {
                return startIndex;
            }
        }
        return 0;
    });

    useEffect(() => {
        if (!hasInitialStepIDBeenSet.current && initialStepID) {
            const newStartIndex = form.steps.findIndex(step => step.id === initialStepID);
            if (newStartIndex !== -1 && newStartIndex !== lastStepIndex) {
                setLastStepIndex(newStartIndex);
            }
            hasInitialStepIDBeenSet.current = true; // Set the flag to true once initialStepID has been applied
        }
    }, [lastStepIndex, form.steps, initialStepID]);

    const [renderableSteps, lastClearableStepIndex] = useMemo(() => {
        // Ensure steps is always an array.
        let steps = form.steps ? form.steps.slice(0, lastStepIndex + 1) : [];

        const lastClearableIndex = (() => {
            for (let i = steps.length - 1; i >= 0; i--) {
                if (steps[i]?.hidePreviousSteps) return i;
            }
            return -1;
        })();

        if (lastClearableIndex !== -1) {
            steps = steps.slice(lastClearableIndex);
        }
        return [steps, lastClearableIndex];
    }, [lastStepIndex, form.steps]);

    const lastStep = renderableSteps[renderableSteps.length - 1];
    const lastStepRef = useRef<HTMLDivElement>(null);
    const [canContinue, setCanContinue] = useState(!!lastStep?.allowSubmit ? lastStep.allowSubmit(props.value, props?.otherValuesMap?.[lastStepIndex]) : true);
    const showBackButton = lastClearableStepIndex !== -1 && !lastStep?.hideBackButton;

    useEffect(() => {
        if (!!lastStep?.allowSubmit) {
            setCanContinue(lastStep.allowSubmit(props.value, props?.otherValuesMap?.[lastStepIndex]));
        }
    }, [lastStep, lastStepIndex, props?.otherValuesMap, props.value]);

    useEffect(() => {
        const handleScroll = async () => {
            if (lastStep?.hidePreviousSteps || (lastStepIndex === 0 && !lastStep?.hidePreviousSteps)) {
                await UIUtils.scrollToTop(3000, 50);
            } else if (lastStepRef.current) {
                await UIUtils.scrollToTop(3000, 50, lastStepRef.current);
            }
        };
        if (lastStepIndex >= 0) {
            handleScroll();
        }
    }, [lastStep?.hidePreviousSteps, lastStepIndex]);

    useEffect(() => {
        if (continueButtonRef.current) {
            updateAppState({ formContinueButtonRef: continueButtonRef });
        }
    }, [continueButtonRef, updateAppState, lastStep?.hideContinueButton]);

    async function handleContinue() {
        let allStepsValid = true;
        let aggregatedData = getValues() as T;

        const registeredStepIds = renderableSteps
            .map(step => step.id)
            .filter(id => formStepsState[id] && formStepsState[id]?.methods && !form.steps.find(step => step.id === id)?.shouldSkip);

        // Validates Stripe payment fields before confirming payment. https://docs.stripe.com/js/elements/submit
        // Validates card transactions and not express checkout transactions (e.g. Google/Apple Pay).
        if (stripe && stripeElements && !stripeExpressPaymentType) {
            // Clear existing existing Stripe payment element error state before validating
            updateAppState({ asyncErrors: undefined, stripePaymentElementError: undefined });

            const { error: paymentValidationError } = await stripeElements?.submit();

            if (!!paymentValidationError) {
                allStepsValid = false;
                AnalyticsUtils.sendDataDogLog("error", "PAYMENT - client validation error", { paymentValidationError });

                // Focus on payment element. Note, this will only focus on the Card element since Stripe treats the entire form as a single element.
                const paymentElement = stripeElements.getElement("payment");
                paymentElement?.focus();
            }
        }

        const handleSubmitForStep = (stepId: string) => {
            const methods = formStepsState[stepId]?.methods;

            // Clear any existing async errors before validating
            updateAppState({ asyncErrors: undefined });

            if (methods) {
                return methods.handleSubmit(
                    async (data, e) => {
                        const updatedParentFormValues = UIUtils.deepMerge(aggregatedData, data, form.schema);
                        aggregatedData = updatedParentFormValues.mergedBase;
                    },
                    (errors, e) => {
                        allStepsValid = false;
                        console.log("Errors:", errors);
                    }
                )();
            }
        };

        const stepIdsWithMethods = registeredStepIds.filter(stepId => formStepsState[stepId]);

        for (const stepId of stepIdsWithMethods) {
            await handleSubmitForStep(stepId);
        }

        if (allStepsValid) {
            setIsValidating(true);

            const isParentValid = lastStep?.allowContinue ? await lastStep.allowContinue(aggregatedData, otherValuesMap?.[lastStep.id]) : true;
            const isParentObject = form.schema.safeParse(isParentValid);
            const updatedValues = isParentObject.success ? (isParentValid as T) : aggregatedData;

            if (!isParentValid) {
                setIsValidating(false);
                return; // Stop further processing if validation fails
            }

            reset(updatedValues);

            // ignore for redirect
            if (!lastStep?.redirect) {
                setIsValidating(false);
            }

            if (onSubmitStep && lastStep) {
                await onSubmitStep(updatedValues, lastStep);
            }
            if (form.onContinue && lastStep) {
                try {
                    await form.onContinue(updatedValues, lastStep);
                } catch (e) {}
            }
            const redirectFn = lastStep?.redirect || form.redirect;
            if (redirectFn && lastStep) {
                try {
                    await redirectFn(updatedValues, lastStep);
                } catch (error) {
                    setIsValidating(false);
                }
            }

            const isFinalStep = lastStepIndex === form.steps.length - 1;

            if (isFinalStep) {
                return;
            }

            const startIndexForSearch = lastStepIndex + 1;
            const nextStepIndex = form.steps.findIndex((step, index) => index >= startIndexForSearch && step.shouldSkip !== true);

            if (!!form.steps && lastStepIndex < form.steps.length - 1) {
                // If findIndex returns -1, it means no such step was found; keep the current lastStepIndex.
                // Otherwise, update lastStepIndex with found nextStepIndex.
                setLastStepIndex(nextStepIndex !== -1 ? nextStepIndex : lastStepIndex);
            }

            if (onStepChange && lastStepIndex >= 0) {
                const indexForChangeHandler = nextStepIndex !== -1 ? nextStepIndex : lastStepIndex;
                const currentStep = form?.steps?.[indexForChangeHandler];
                if (!!currentStep) {
                    if (isParentObject.success) {
                        onStepChange(indexForChangeHandler, currentStep, updatedValues);
                    } else {
                        onStepChange(indexForChangeHandler, currentStep);
                    }
                }
            }
        }
    }

    async function handleBack() {
        // Initialize previousStepIndex with -1 to indicate not found.
        let previousStepIndex = -1;

        // Iterate backwards from the step before the current one to find the previous non-skippable step.
        for (let index = lastStepIndex - 1; index >= 0; index--) {
            const step = form.steps[index];

            if (step?.shouldSkip) continue;

            previousStepIndex = index;
            break;
        }

        // Set to previous valid step index found, or stay at the current step if no previous valid step is found.
        setLastStepIndex(previousStepIndex !== -1 ? previousStepIndex : lastStepIndex);

        // Notify any necessary hooks or state management about the step change.
        const previousStep = form.steps?.[previousStepIndex];
        if (onStepChange && !!previousStep) {
            onStepChange(previousStepIndex, previousStep, value);
        }
    }

    const renderHeader = useCallback(() => {
        if (form.header) {
            return form.header(value, otherValuesMap, !!isUpdating);
        }
    }, [form, isUpdating, otherValuesMap, value]);

    const renderFooter = useCallback(() => {
        if (form.footer) {
            return form.footer(value, otherValuesMap, !!isUpdating);
        }
    }, [form, isUpdating, otherValuesMap, value]);

    return (
        <FormMethodsProvider onFormContinue={handleContinue} isValidating={isValidating} canContinue={canContinue}>
            <div className="relative flex min-h-screen flex-col">
                <header>{renderHeader()}</header>
                {isUpdating && (
                    <div className={`absolute inset-0 flex items-center justify-center`}>
                        <LoaderWithText spinnerSize="xl" />
                    </div>
                )}
                <Container>
                    <form className="flex flex-col">
                        {!isUpdating && (
                            <>
                                {renderableSteps.map((step, index) => (
                                    <div key={step.id}>
                                        <StepRenderer
                                            step={step}
                                            index={index}
                                            value={value}
                                            lastStepIndex={lastStepIndex}
                                            otherValuesMap={otherValuesMap}
                                            register={registerStep}
                                            unregister={unregisterStep}
                                            lastStepRef={lastStepRef}
                                        />
                                    </div>
                                ))}
                                {hasUnknownError && <ContactSpotError phone={phone} hours={hours} />}
                                {!lastStep?.hideCtaRow && (
                                    <div className="mt-7 grid grid-cols-[auto_1fr] gap-y-3 border-t border-stroke-secondary pt-6 lg:grid-cols-3 lg:gap-y-0">
                                        <div className="col-span-1">
                                            {showBackButton && (
                                                <Button
                                                    variant="ghost"
                                                    type="button"
                                                    startDecorator={<FontAwesomeIcon icon={faChevronLeft} />}
                                                    onClick={async () => await handleBack()}
                                                    disabled={!canContinue || isValidating}
                                                    className="mr-auto"
                                                >
                                                    Back
                                                </Button>
                                            )}
                                        </div>
                                        <div className="col-span-1 lg:col-span-1 lg:col-start-3 lg:justify-self-end">
                                            {lastStep?.bottomWidget && !!value && <div className="flex justify-end">{React.createElement(lastStep.bottomWidget, { value })}</div>}
                                        </div>
                                        <div className="relative col-span-2 flex justify-center lg:col-span-1 lg:col-start-2 lg:row-start-1">
                                            {!lastStep?.hideContinueButton && (
                                                <Button
                                                    endDecorator={!canContinue || isValidating ? <LoadingSpinner size="sm" /> : <FontAwesomeIcon icon={faChevronRight} />}
                                                    onClick={async () => await handleContinue()}
                                                    type="button"
                                                    disabled={!canContinue || isValidating}
                                                    className="size-full px-4 py-3 lg:w-auto"
                                                    ref={continueButtonRef}
                                                >
                                                    <div className="flex items-center gap-2">{lastStep?.continueButtonTitle ?? "Continue"}</div>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="mt-6 flex flex-col justify-center gap-8 lg:mt-10">
                                    {lastStep?.disclaimerContent ? (
                                        <>
                                            {typeof lastStep.disclaimerContent === "function" ? (
                                                <>
                                                    {React.createElement(lastStep.disclaimerContent, {
                                                        value: value,
                                                        isLastVisible: lastStepIndex === form.steps.length - 1
                                                    })}
                                                </>
                                            ) : (
                                                <>{lastStep.disclaimerContent}</>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </form>
                </Container>
            </div>
            {renderFooter()}
        </FormMethodsProvider>
    );
}

interface FormMethodsContextInterface {
    onFormContinue: () => Promise<void>;
    isValidating?: boolean;
    canContinue?: boolean;
}

const FormMethodsContext = createContext<FormMethodsContextInterface | undefined>(undefined);

export const useFormMethodsContext = () => {
    const context = useContext(FormMethodsContext);
    if (context === undefined) {
        throw new Error("useFormMethodsContext must be used within a FormMethodsProvider");
    }
    return context;
};

interface FormMethodsProviderProps {
    children: ReactNode;
    onFormContinue: () => Promise<void>;
    isValidating?: boolean;
    canContinue?: boolean;
}

const FormMethodsProvider: React.FC<FormMethodsProviderProps> = ({ children, onFormContinue, isValidating, canContinue }) => {
    return <FormMethodsContext.Provider value={{ onFormContinue, isValidating, canContinue }}>{children}</FormMethodsContext.Provider>;
};
