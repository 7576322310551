import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogDescription } from "./ui/Dialog";
import { IconButton } from "./ui/IconButton";
import { UIUtils } from "../utils/UIUtils";
import Strings from "../utils/Strings.constants";
import { DialogProps } from "@/shared/types/Modals";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

const MemberCenterModal: React.FC<DialogProps> = props => {
    const { open, onClose } = props;

    function handleOverlayClick() {
        if (onClose) {
            onClose(false);
        }
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    return (
        <div onClick={handleOverlayClick}>
            <Dialog open={open}>
                <DialogContent onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                    <VisuallyHidden asChild>
                        <DialogTitle>It looks like you already have a policy with us</DialogTitle>
                    </VisuallyHidden>
                    <VisuallyHidden asChild>
                        <DialogDescription />
                    </VisuallyHidden>
                    <DialogClose asChild>
                        <IconButton onClick={() => onClose && onClose(false)}>
                            <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                        </IconButton>
                    </DialogClose>
                    <div className="flex w-full flex-col gap-4 text-content-primary">
                        <h3 className="pr-8 text-lg font-semibold">It looks like you already have a policy with us</h3>
                        <p className="text-base">
                            Please call us at{" "}
                            <a href={`tel:${UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}`}>
                                <span className="text-content-brand underline">{UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}</span>
                            </a>{" "}
                            to discuss what pet insurance options may be available for you.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MemberCenterModal;
