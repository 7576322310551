import { useReducer, useCallback } from "react";
import { UseFormReturn } from "react-hook-form";

type FormStepsState = {
    [stepId: string]: {
        methods: UseFormReturn<any>;
    };
};

type FormStepsAction = { type: "REGISTER"; stepId: string; methods: UseFormReturn<any> } | { type: "UNREGISTER"; stepId: string };

function formStepsReducer(state: FormStepsState, action: FormStepsAction): FormStepsState {
    switch (action.type) {
        case "REGISTER":
            return {
                ...state,
                [action.stepId]: {
                    methods: action.methods
                }
            };
        case "UNREGISTER":
            const newState = { ...state };
            delete newState[action.stepId];
            return newState;
        default:
            return state;
    }
}

export function useFormSteps() {
    const [formStepsState, dispatch] = useReducer(formStepsReducer, {});

    const register = useCallback((stepId: string, methods: UseFormReturn<any>) => {
        dispatch({ type: "REGISTER", stepId, methods });
    }, []);

    const unregister = useCallback((stepId: string) => {
        dispatch({ type: "UNREGISTER", stepId });
    }, []);

    return {
        formStepsState,
        register,
        unregister
    };
}
