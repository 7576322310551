import { useEffect, useRef, useState } from "react";
import { ConditionalRenderProps, OtherProps } from "../types/Form";
import { FieldValues } from "react-hook-form";

export function ConditionalRender<T extends FieldValues, K extends keyof T = keyof T, O = OtherProps>(props: ConditionalRenderProps<T, K, O>) {
    const { children, shouldRender, monitor, otherValues } = props;
    const prevProps = useRef(props);
    const [prevChildrenState, setPrevChildrenState] = useState(children);

    useEffect(() => {
        prevProps.current = { children, shouldRender, monitor, otherValues };
    }, [children, shouldRender, monitor, otherValues]);

    useEffect(() => {
        if (!shouldRender || shouldRender(prevProps.current.monitor, monitor, otherValues)) {
            setPrevChildrenState(children);
        }
    }, [children, monitor, otherValues, shouldRender]);

    return prevChildrenState;
}
